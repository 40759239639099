import {
    get,
    put,
    patch,
    post,
    fetch,
    get2
} from "../ApiDecorator";
const Report = {
    // 教学应用报告接口 
    // 整体应用情况数量统计
    countTotal(data) {
        return get('/learn/mySchoolReport/overallApplication', data)
    },
    // 导出
    export (data) {
        return get2('/learn/mySchoolReport/excelReport', data)
    },
    // 获取学期第一天
    getTermFirstDay() {
        return get('/learn/mySchoolReport/termFirstDay')
    },
    // 各年级上课统计柱状图
    gradeCourseTotal(data) {
        return get('/learn/mySchoolReport/getClassStatisticsByGrade', data)
    },
    // 本校教师上课排名
    teaRank(data) {
        return get('/learn/mySchoolReport/rankTeachersGiveClass', data)
    },
    // 上课次数环比
    courseCount(data) {
        return get('/learn/mySchoolReport/calculateClassCountRatio', data)
    },
    // 投影次数环比
    videoCount(data) {
        return get('/learn/mySchoolReport/calculateProjectionMom', data)
    },
    // 最近15天应用数据
    applicationData(data) {
        return get('/learn/mySchoolReport/fifteenDaysApplicationData', data)
    },
    // 各年级应用对比
    gradeApplicationData(data) {
        return get('/learn/mySchoolReport/applicationComparisonByGrade', data)
    },
    // 本校上课时间分布(折线图)
    courseTimeData(data) {
        return get('/learn/mySchoolReport/schoolTimeDistribution', data)
    },
    // 本校上课时间分布(饼图)
    courseTimeDataPie(data) {
        return get('/learn/mySchoolReport/takeClassTypeDistribution', data)
    },
    // 本校上课时间分布(柱状图)
    courseTimeDataBar(data) {
        return get('/learn/mySchoolReport/takeClassTypeDistributionGroupGrade', data)
    },
    // 同步课达成率
    courseRate(data) {
        return get('/learn/mySchoolReport/courseYieldRate', data)
    },
    // 特色课程应用情况
    featuredCourse(data) {
        return get('/learn/mySchoolReport/featuredCourse', data)
    },
    // 软硬笔体系课
    systemCourse(data) {
        return get('/learn/mySchoolReport/systemCourse', data)
    },
    // 学生App应用学习排名
    stuAppRank(data) {
        return get('/learn/mySchoolReport/studentAPPLearnRank', data)
    },
    // 观看最多的课程Top10
    watchMost(data) {
        return get('/learn/mySchoolReport/mostWatchedCoursesTop10', data)
    },
    // 各年级学生App学习统计
    stuAppStudy(data) {
        return get('/learn/mySchoolReport/studentsAPPLearnGrades', data)
    },
    // 各年级学生App测评次数统计
    stuAppTest(data) {
        return get('/learn/mySchoolReport/studentsAPPAppraisalGrades', data)
    },

    // 前后测报告接口
    // 参与情况
    joinInfo(data) {
        return get('/learn/beforeAfterEvalReport/participation', data)
    },
    // 各年级参与率
    joinRate(data) {
        return get('/learn/beforeAfterEvalReport/participationChart', data)
    },
    // 书写习惯-坐姿
    sitPosition(data) {
        return get('/learn/beforeAfterEvalReport/sitPosition', data)
    },
    // 坐姿错误人数
    sitPositionChat(data) {
        return get('/learn/beforeAfterEvalReport/sitPositionChat', data)
    },
    // 书写习惯-握姿
    holdAPenPosture(data) {
        return get('/learn/beforeAfterEvalReport/holdAPenPosture', data)
    },
    // 握姿错误人数
    holdAPenPostureChart(data) {
        return get('/learn/beforeAfterEvalReport/holdAPenPostureChart', data)
    },
    // 全校个等级学员占比
    studentLvRatioCompare(data) {
        return get('/learn/beforeAfterEvalReport/studentLvRatioCompare', data)
    },
    // 一级维度全校成绩对比
    oneDScoreCompare(data) {
        return get('/learn/beforeAfterEvalReport/oneDScoreCompare', data)
    },
    // 与上一学期测评对比
    oneDScoreCompareDiff(data) {
        return get('/learn/beforeAfterEvalReport/oneDScoreCompareDiff', data)
    },
    // 与上一学期测评对比
    oneDScoreCompareDiffV2(data) {
        return get('/learn/beforeAfterEvalReport/oneDScoreCompareDiffV2', data)
    },
    // 本年级各等级学生人数占比
    gradeGradeRatio(data) {
        return get('/learn/beforeAfterEvalReport/gradeGradeRatio', data)
    },
    // 本年级各等级学生人数占比
    gradeGradeRatioV2(data) {
        return get('/learn/beforeAfterEvalReport/gradeGradeRatioV2', data)
    },
    // 本年级各班优秀率对比
    gradeClassExcellentRateDiff(data) {
        return get('/learn/beforeAfterEvalReport/gradeClassExcellentRateDiff', data)
    },
    // 优秀作品
    getWorks(data) {
        return get('/learn/beforeAfterEvalReport/level4WorkPage', data)
    },
    // 导出前后测报告
    exportTest(data) {
        return get2('/learn/beforeAfterEvalReport/exportExcel', data)
    },
    // 导出前后测报告
    exportTestV2(data) {
        return get2('/learn/beforeAfterEvalReport/exportExcelV2', data)
    },
    // 前后测报告--教师
    // 优秀作品
    getparticipation(data) {
        return get('/learn/beforeAfterEvalReportTeacher/participation', data)
    },
    // 书写习惯-坐姿
    getsitPosition(data) {
        return get('/learn/beforeAfterEvalReportTeacher/sitPosition', data)
    },
    // 书写习惯-握笔姿势
    getholdAPenPosture(data) {
        return get('/learn/beforeAfterEvalReportTeacher/holdAPenPosture', data)
    },
    // 本班单项成绩等级占比
    getstudentLvRatioCompare(data) {
        return get('/learn/beforeAfterEvalReportTeacher/studentLvRatioCompare', data)
    },
    // 本班单项成绩等级占比-新版
    getstudentLvRatioCompareV2(data) {
        return get('/learn/beforeAfterEvalReportTeacher/studentLvRatioCompareV2', data)
    },
    // 一级维度成绩
    getoneDScoreCompare(data) {
        return get('/learn/beforeAfterEvalReportTeacher/oneDScoreCompare', data)
    },
    // 一级维度成绩
    getoneDScoreCompareV2(data) {
        return get('/learn/beforeAfterEvalReportTeacher/oneDScoreCompareV2', data)
    },
    // “优秀+”作品样例 分页
    getlevel4WorkPage(data) {
        return get('/learn/beforeAfterEvalReportTeacher/level4WorkPage', data)
    },
    // 本班优秀 学生名单
    geteliteStudent(data) {
        return get('/learn/beforeAfterEvalReportTeacher/eliteStudent', data)
    },
    // 本班优秀 学生名单
    geteliteStudentV2(data) {
        return get('/learn/beforeAfterEvalReportTeacher/eliteStudentV2', data)
    },
    getTakePartGradeList(data) {
        return get('/learn/beforeAfterEvalReport/getTakePartGradeList', data)
    },
    // 导出 Excel 教师前后
    getexportExcel(data) {
        return get2('/learn/beforeAfterEvalReportTeacher/exportExcel', data)
    },
    // // 导出 Excel 教师前后
    // getexportExcel(data) {
    //     return get2('/learn/beforeAfterEvalReportTeacher/exportExcel', data)
    // },
    // 导出 Excel 教师前后
    getexportExcelV2(data) {
        return get2('/learn/beforeAfterEvalReportTeacher/exportExcelV2', data)
    },
    // // 导出 Excel 教师月测
    // getexportExcel_month(data) {
    //     return get2('/learn/monthEvalReportTea/exportExcel', data)
    // },

    //校长查年级
    //查询教师关联班级
    getTeacherClass(data) {
        return get('learn/teach/getTeacherClass', data)
    },

    //月测
    getparticipationMonth(data) {
        return get('learn/monthEvalReport/monthEvalName', data)
    },
    //周测
    getparticipationWeek(data) {
        return get('learn/monthEvalReport/examEvalName', data)
    },
    //年级top榜
    getgradeTopStudent(data) {
        return get('learn/monthEvalReport/gradeTopStudent', data)
    },
    // // 导出前后测报告月测
    exportTestmonth(data) {
        return get2('/learn/monthEvalReport/exportExcel', data)
    },
    // // 导出前后测报告月测
    exportTestmonthV2(data) {
        return get2('/learn/monthEvalReport/exportExcelV2', data)
    },

    // 月测报告-教师
    // 获取月测报告名称
    monthParticipation(data) {
        return get('/learn/monthEvalReport/monthEvalName', data)
    },
    // 整体情况
    overallSituation(data) {
        return get('/learn/monthEvalReportTea/overall-situation', data)
    },
    // 本班单项成绩等级占比
    monthLevelStuNumGroup(data) {
        return get('/learn/monthEvalReportTea/levelStuNumGroup', data)
    },
    // 本班学生测评等级列表
    monthClassStuScoreList(data) {
        return get('/learn/monthEvalReportTea/classStuScoreList', data)
    },
    // AI测评维度成绩
    monthAI(data) {
        return get('/learn/monthEvalReportTea/aiScore', data)
    },
    //月测校长
    // 参与情况
    joinInfoOverall(data) {
        return get('/learn/monthEvalReport/overall-participation', data)
    },
    // 各年级参与率
    joinRateSch(data) {
        return get('/learn/monthEvalReport/participationChart', data)
    },
    // 全校个等级学员占比
    studentLvRatioCompareStu(data) {
        return get('/learn/monthEvalReport/studentLvRatioCompare', data)
    },
    //全校成绩对比
    oneDScoreCompareStu(data) {
        return get('/learn/monthEvalReport/oneDScoreCompare', data)
    },
    // 本年级各等级学生人数占比
    gradeGradeRatioStu(data) {
        return get('/learn/monthEvalReport/gradeGradeRatio', data)
    },
    // 本年级各班优秀率对比
    gradeClassExcellentRateDiffStu(data) {
        return get('/learn/monthEvalReport/gradeClassExcellentRateDiff', data)
    },
    // 与上一学期测评对比
    oneDScoreCompareDiffSch(data) {
        return get('/learn/monthEvalReport/oneDScoreCompareDiff', data)
    },
    // 获取年级分析 年级列表
    getTakePartGradeListSch(data) {
        return get('/learn/monthEvalReport/getTakePartGradeList', data)
    },
    // 优秀作品
    getWorksSch(data) {
        return get('/learn/monthEvalReport/getWorkPageByLevel', data)
    },
    // 优秀作品
    getWorksSchV2(data) {
        return get('/learn/monthEvalReport/getWorkPageByLevelV2', data)
    },
    // 全校个等级学员占比
    studentLvRatioCompareV2(data) {
        return get('/learn/beforeAfterEvalReport/studentLvRatioCompareV2', data)
    },
    // 新版学生个人报告 /eval-report/ind-report
    getStuData(data) {
        return get('/learn/eval-report/ind-report', data)
    },
    // 新版学生个人报告 /eval-report/ind-report
    getindRreport(data) {
        return get('/learn/eval-report/open/ind-report', data)
    },
    //批量下载 报告
    //根据账号生成
    getbatchdownload(data) {
        return get('/learn/literacy-pdf-reports/check-download', data)
    },
    //批量下载
    getgenerate(data) {
        return get('/learn/literacy-pdf-reports/batch-generate', data)
    },
    // 本班单项成绩等级占比-新版
    getstudentLvRatioCompareV2_month(data) {
        return get('/learn/monthEvalReport/studentLvRatioCompareV2', data)
    },
    // 本班单项成绩等级占比-新版
    getoneDScoreCompareV2_month(data) {
        return get('/learn/monthEvalReport/oneDScoreCompareV2', data)
    },
    // 本班单项成绩等级占比-新版
    getoneDScoreCompareDiffV2_month(data) {
        return get('/learn/monthEvalReport/oneDScoreCompareDiffV2', data)
    },
    // 本班单项成绩等级占比-新版
    getgradeGradeRatioV2_month(data) {
        return get('/learn/monthEvalReport/gradeGradeRatioV2', data)
    },
    // 本班单项成绩等级占比-新版
    getgradeClassExcellentRateDiffV2_month(data) {
        return get('/learn/monthEvalReport/gradeClassExcellentRateDiffV2', data)
    },
    // 本班单项成绩等级占比-新版
    getgradeTopStudentV2_month(data) {
        return get('/learn/monthEvalReport/gradeTopStudentV2', data)
    },
    // 优秀作品
    getWorksSch_month(data) {
        return get('/learn/monthEvalReport/getWorkPageByLevelV2', data)
    },

    // 教师新旧版月测
    // 整体情况
    getoverallSituation_month(data) {
        return get('/learn/monthEvalReportTea/overall-situationV2', data)
    },
    getlevelStuNumGroupV2_month(data) {
        return get('/learn/monthEvalReportTea/levelStuNumGroupV2', data)
    },
    // 本班学生测评等级列表
    getclassStuScoreListV2_month(data) {
        return get('/learn/monthEvalReportTea/classStuScoreListV2', data)
    },
    // AI测评维度成绩
    getaiScoreV2_month(data) {
        return get('/learn/monthEvalReportTea/aiScoreV2', data)
    },
    // AI测评维度成绩
    getstudentLvRatioCompareTV2_month(data) {
        return get('/learn/beforeAfterEvalReportTeacher/studentLvRatioCompareV2', data)
    },
    getrankClassStuDiff(data) {
        return get('/learn/beforeAfterEvalReportTeacher/rankClassStuDiff', data)
    },
}
// 默认全部倒出
export default Report;